//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export const defaultErrorMessages = {
  401: `Ошибка авторизации.`,
  403: `Доступ к ресурсу запрещен.`,
  404: `Ресурс не найден.`,
  405: `Операция отклонена.`,
  422: 'Ошибка валидации.',
  500: 'Ошибка на сервере.',
  502: 'Ошибка на сервере.',
  503: 'Сервер недоступен.',
}
export default {
  name: 'EmptyLayout',
  props: {
    error: {
      statusMessage: String,
      description: String,
      statusCode: Number,
      message: String,
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      defaultErrorMessages,
      otherError: 'Произошла ошибка',
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}
