import { convertDate } from '~/utils/index'

export const normalizePhone = (phoneValue) => {
  return phoneValue ? '+' + phoneValue : null
}
export const normalizeUser = (user) => {
  // @/assets/images/empty-avatart.png

  const getPersoneAvatar = (user) => {
    const staticUrl = process.env.baseURLStatic
    const imgPath = user.personal?.img?.path || user.img?.path || null
    return imgPath
      ? `${staticUrl}/resize/128x128/${imgPath}`
      : require('@/assets/images/empty-avatart.png')
  }

  const getProductImg = (img) => {
    const staticUrl = process.env.baseURLStatic
    const imgPath = img?.path || img || null
    return imgPath
      ? `${staticUrl}/resize/128x128/${imgPath}`
      : require('@/assets/images/product_placholder.svg')
  }

  const getPersonePhone = (user) => {
    const phoneValue = user.contact?.phone
    if (!phoneValue) return '-'
    return normalizePhone(phoneValue)
  }
  const contact = user.contact?.identifiers ?? user.contact ?? null
  if (contact) {
    contact.email_is_confirmed = user.contact?.is_confirmed ?? false
  }

  user.contact = contact
  return {
    name: `${user.personal?.first_name || '-'} ${
      user.personal?.last_name || '-'
    }`,
    contact: user.contact,
    img: getPersoneAvatar(user),
    phone: getPersonePhone(user),
    uuid: user.uuid,
    birthDate: user.personal.birth_date
      ? convertDate(user.personal.birth_date)
      : null,
    gender: user.personal.gender
      ? parseInt(user.personal.gender) === 1
        ? 'Мужской'
        : 'Женский'
      : null,
    createdAt: user.personal.created_at
      ? convertDate(user.personal?.created_at)
      : null,
  }
}

export const fromStampToDate = (stamp, options) => {
  const date = new Date(stamp * 1000)
  return date.toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    ...options,
  })
  // .replace('.', '-')
  // .replace(',', ' '),
}

export const userGenderBirthDate = (user) => {
  return {
    gender: user.personal.gender === 1 ? 'Мужской' : 'Женский',
    birthDate:
      user.personal.birth_date &&
      user.personal.birth_date.split('-').reverse().join('.'),
  }
}

export const formatDateFromIsoToNormal = (date) => {
  if (!date) return '-'
  return date.split('-').reverse().join('.')
}

export const getProductImg = (imgArr) => {
  const staticUrl = process.env.baseURLStatic
  if (!imgArr) return require('@/assets/images/product_placholder.svg')

  const path = Array.isArray(imgArr)
    ? `${staticUrl}/resize/256x256/${imgArr[0].path}`
    : `${staticUrl}/resize/256x256/${imgArr.path}`

  return path
}

export const getTime = (time) => {
  const [hour, minutes] = time.split(':')
  return `${hour}:${minutes}`
}
