export default function ({ $config }, inject) {
  /* Плагин вызывается только на клиента. 
  Сразу в начале рендера добавляется тег скрипт addScript()
  После добавления скрипта вызывается initMap, в котором переключаем флаг mapLoaded
  и если вдруг была попытка открыть карту до загрузки скрипта рендерим её
  */
  let mapLoaded = false
  let mapWaiting = null
  const initMap = () => {
    mapLoaded = true
    if (mapWaiting) {
      renderMap(mapWaiting)
      mapWaiting = null
    }
  }
  // Создание тега скрипта, добавление нужных атрибутов async и initMap.
  // Добавлени скрипта в document.head
  const addScript = () => {}
  // addScript()

  const renderMap = ({ canvas, location }) => {
    // eslint-disable-next-line no-undef
    return new ymaps.Map(canvas, {
      // Координаты центра карты.
      // Порядок по умолчанию: «широта, долгота».
      // Чтобы не определять координаты центра карты вручную,
      // воспользуйтесь инструментом Определение координат.
      center: [location.lat, location.lng],
      // Уровень масштабирования. Допустимые значения:
      // от 0 (весь мир) до 19.
      zoom: 18,
    })
  }
  // или сохраняет карту если скрипт ещё не загружен
  // или напрямую рендерит её
  const showMap = (params) => {
    if (mapLoaded) renderMap(params)
    else mapWaiting = params
  }

  const initScript = () => {
    return new Promise((resolve, reject) => {
      if (mapLoaded) return resolve()
      const script = document.createElement('script')
      script.src = `https://api-maps.yandex.ru/2.1/?apikey=${$config.MAP_KEY}&lang=ru_RU&onload=initMap`
      script.async = true
      window.initMap = () => {
        resolve()
      }
      document.head.appendChild(script)
      mapLoaded = true
    })
  }

  inject('maps', {
    renderMap,
    initScript,
  })
}
