function initialState() {
  return {
    items: [],
  }
}
export const namespaced = true

export const state = () => {
  return initialState()
}
export const mutations = {
  ADD_ITEMS(state, value) {
    state.items = state.items.concat(value)
  },
  ADD_ITEM(state, value) {
    state.items.unshift(value)
  },
  UPDATE_ITEM(state, order) {
    const idx = state.items.findIndex((el) => el.id === order.id)
    state.items.splice(idx, 1, order)
  },
  SET_ITEMS(state, value) {
    state.items = value
  },
}
export const actions = {}
export const getters = {
  HAS_NEW_ORDERS(state) {
    return state.items.some((el) => el.state.value === 'new')
  },
  NEW_ORDERS_COUNT(state) {
    const newOrders = state.items.filter((el) => el.state.value === 'new')
    return newOrders.length
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
