//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    height: {
      type: Number,
      default: null,
    },
    rostType: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'transparent', 'white'].includes(value)
      },
    },
    icisSize: {
      type: String,
      default: '',
      validator(value) {
        return ['lg', ''].includes(value)
      },
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    localHeight() {
      const defaultValue = this.$vuetify.breakpoint.smAndDown ? 40 : 48
      return this.height || defaultValue
    },
    typeConfig() {
      const conf = {
        default: {
          'background-color': 'grey-lighter',
        },
        transparent: {
          'background-color': 'transparent',
        },
        white: {
          'background-color': 'white',
        },
      }
      return conf[this.rostType]
    },
    bindedAttrs() {
      // because v-bind can't merge :class and v-bind classes. Only symple class="my-class"
      const classes = []
      this.icisSize && classes.push(`icis-text-field_size_${this.icisSize}`)
      this.rostType && classes.push(`icis-text-field_type_${this.rostType}`)

      let attrs = {
        solo: true,
        flat: true,
        class: classes,
        ...this.typeConfig,
        ...this.$attrs,
      }

      if (this.localHeight) {
        attrs = {
          ...attrs,
          class: [...attrs.class, 'icis_disable-min-height'],
        }
      }
      return attrs
    },

    innerRules() {
      const rules = this.rules.slice()
      if (this.isRequired) {
        rules.push((v) => {
          return !!v || 'Заполните поле'
        })
      }

      if (this.bindedAttrs.type === 'number') {
        rules.push((v) => {
          if (v === null) {
            return true
          }
          if (v === '') {
            return 'Введите число'
          }
          return !isNaN(Number(v)) || 'Введите число'
        })
      }
      return rules
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    blur() {
      this.$refs.input.blur()
    },
    validate() {
      this.$refs.input.validate()
    },
  },
}
