import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _626e10fa = () => interopDefault(import('../modules/referrals/pages/referral-edit.vue' /* webpackChunkName: "" */))
const _3cc29542 = () => interopDefault(import('../modules/referrals/pages/referral-stats.vue' /* webpackChunkName: "" */))
const _47f4e261 = () => interopDefault(import('../modules/referrals/pages/referral-add.vue' /* webpackChunkName: "" */))
const _3ebb9136 = () => interopDefault(import('../modules/referrals/pages/referrals.vue' /* webpackChunkName: "" */))
const _05a6d548 = () => interopDefault(import('../modules/cluster-offer/pages/cluster-offer.vue' /* webpackChunkName: "" */))
const _3b398279 = () => interopDefault(import('../modules/cluster-offer/pages/list-offers.vue' /* webpackChunkName: "" */))
const _5310c25e = () => interopDefault(import('../modules/partner/pages/reports.vue' /* webpackChunkName: "" */))
const _0d6a6891 = () => interopDefault(import('../modules/partner/pages/organization.vue' /* webpackChunkName: "" */))
const _1e377d5a = () => interopDefault(import('../modules/partner/pages/partner.vue' /* webpackChunkName: "" */))
const _d85fcd34 = () => interopDefault(import('../modules/partner/pages/newPartner.vue' /* webpackChunkName: "" */))
const _a73861ee = () => interopDefault(import('../modules/partner/pages/partners.vue' /* webpackChunkName: "" */))
const _60c3fd30 = () => interopDefault(import('../modules/audience/pages/audienceEdit.vue' /* webpackChunkName: "" */))
const _6740749c = () => interopDefault(import('../modules/audience/pages/audienceNew.vue' /* webpackChunkName: "" */))
const _23b781a5 = () => interopDefault(import('../modules/audience/pages/audiences.vue' /* webpackChunkName: "" */))
const _9de59f84 = () => interopDefault(import('../modules/complaints/pages/complaints.vue' /* webpackChunkName: "" */))
const _3f59f1fa = () => interopDefault(import('../modules/complaints/pages/products.vue' /* webpackChunkName: "" */))
const _6be054c8 = () => interopDefault(import('../modules/complaints/pages/stores.vue' /* webpackChunkName: "" */))
const _c512c906 = () => interopDefault(import('../modules/employees-documents/pages/single-collection.vue' /* webpackChunkName: "" */))
const _26a46c99 = () => interopDefault(import('../modules/employees-documents/pages/document-collections.vue' /* webpackChunkName: "" */))
const _6a18fbb3 = () => interopDefault(import('../modules/staff-employees/pages/edit-employee.vue' /* webpackChunkName: "" */))
const _160e917c = () => interopDefault(import('../modules/staff-employees/pages/employee.vue' /* webpackChunkName: "" */))
const _a12181b2 = () => interopDefault(import('../modules/staff-employees/pages/employees.vue' /* webpackChunkName: "" */))
const _c92e70c2 = () => interopDefault(import('../modules/order-polygons/pages/polygons.vue' /* webpackChunkName: "" */))
const _714c9de1 = () => interopDefault(import('../modules/supplier-stories/pages/newStory.vue' /* webpackChunkName: "" */))
const _35352f8e = () => interopDefault(import('../modules/supplier-stories/pages/storyDetails.vue' /* webpackChunkName: "" */))
const _2a41a3e2 = () => interopDefault(import('../modules/supplier-stories/pages/editStory.vue' /* webpackChunkName: "" */))
const _2fec3752 = () => interopDefault(import('../modules/supplier-stories/pages/stories.vue' /* webpackChunkName: "" */))
const _a4edb9b0 = () => interopDefault(import('../modules/stories/pages/newStory.vue' /* webpackChunkName: "" */))
const _0c801c00 = () => interopDefault(import('../modules/stories/pages/storyDetails.vue' /* webpackChunkName: "" */))
const _363351a8 = () => interopDefault(import('../modules/stories/pages/editStory.vue' /* webpackChunkName: "" */))
const _4d141a30 = () => interopDefault(import('../modules/stories/pages/stories.vue' /* webpackChunkName: "" */))
const _79daa1b4 = () => interopDefault(import('../modules/push/pages/newPush.vue' /* webpackChunkName: "" */))
const _825bf2b0 = () => interopDefault(import('../modules/push/pages/editPush.vue' /* webpackChunkName: "" */))
const _5b098c65 = () => interopDefault(import('../modules/push/pages/pushs.vue' /* webpackChunkName: "" */))
const _226ad176 = () => interopDefault(import('../modules/modules/pages/module-chips.vue' /* webpackChunkName: "" */))
const _1e76e37c = () => interopDefault(import('../modules/modules/pages/module-chips-edit.vue' /* webpackChunkName: "" */))
const _b7b194d0 = () => interopDefault(import('../modules/modules/pages/module-chips-new.vue' /* webpackChunkName: "" */))
const _05bf0998 = () => interopDefault(import('../modules/modules/pages/modules.vue' /* webpackChunkName: "" */))
const _1174590f = () => interopDefault(import('../modules/market-company/pages/newCompany.vue' /* webpackChunkName: "" */))
const _4228e857 = () => interopDefault(import('../modules/market-company/pages/companyDetails.vue' /* webpackChunkName: "" */))
const _71c24979 = () => interopDefault(import('../modules/market-company/pages/companies.vue' /* webpackChunkName: "" */))
const _6c8ae931 = () => interopDefault(import('../modules/market-company/pages/editCompany.vue' /* webpackChunkName: "" */))
const _334170a3 = () => interopDefault(import('../modules/analytics/pages/chart-details.vue' /* webpackChunkName: "" */))
const _8842b154 = () => interopDefault(import('../modules/analytics/pages/analytics.vue' /* webpackChunkName: "" */))
const _413ecf3e = () => interopDefault(import('../modules/requests/pages/requests.vue' /* webpackChunkName: "" */))
const _030c1cf8 = () => interopDefault(import('../modules/accounting/pages/editSalaryDocument.vue' /* webpackChunkName: "" */))
const _7154c7de = () => interopDefault(import('../modules/accounting/pages/accounting.vue' /* webpackChunkName: "" */))
const _373143c4 = () => interopDefault(import('../modules/accounting/pages/salaryDocumentDetails.vue' /* webpackChunkName: "" */))
const _3fd24c8c = () => interopDefault(import('../modules/accounting/pages/newSalaryDocument.vue' /* webpackChunkName: "" */))
const _41ad106e = () => interopDefault(import('../modules/product-groups/pages/add-products-to-group.vue' /* webpackChunkName: "" */))
const _72c5bc45 = () => interopDefault(import('../modules/product-groups/pages/product-group.vue' /* webpackChunkName: "" */))
const _2cc52504 = () => interopDefault(import('../modules/product-groups/pages/product-groups.vue' /* webpackChunkName: "" */))
const _4bb35b3e = () => interopDefault(import('../modules/products/pages/products.vue' /* webpackChunkName: "" */))
const _43dd0b09 = () => interopDefault(import('../modules/products/pages/newProduct.vue' /* webpackChunkName: "" */))
const _6561971b = () => interopDefault(import('../modules/products/pages/editProduct.vue' /* webpackChunkName: "" */))
const _c84cc2ce = () => interopDefault(import('../modules/gc-customers/pages/single-customer.vue' /* webpackChunkName: "" */))
const _997d4eaa = () => interopDefault(import('../modules/gc-customers/pages/customers.vue' /* webpackChunkName: "" */))
const _5010dad5 = () => interopDefault(import('../modules/orders/pages/returnOrder.vue' /* webpackChunkName: "" */))
const _b5f67a0a = () => interopDefault(import('../modules/orders/pages/editOrder.vue' /* webpackChunkName: "" */))
const _73a73d65 = () => interopDefault(import('../modules/orders/pages/order.vue' /* webpackChunkName: "" */))
const _04ec105e = () => interopDefault(import('../modules/orders/pages/orders.vue' /* webpackChunkName: "" */))
const _24e1dacd = () => interopDefault(import('../modules/auth/pages/password-login.vue' /* webpackChunkName: "" */))
const _f0ff9f9a = () => interopDefault(import('../modules/auth/pages/sms.vue' /* webpackChunkName: "" */))
const _536ab1c3 = () => interopDefault(import('../modules/auth/pages/login.vue' /* webpackChunkName: "" */))
const _0d3f4692 = () => interopDefault(import('../pages/AppMobileMenuPage.vue' /* webpackChunkName: "pages/AppMobileMenuPage" */))
const _61eeb70a = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _15ed0cb3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/referrals/:id/edit",
    component: _626e10fa,
    name: "referral-edit"
  }, {
    path: "/referrals/:id",
    component: _3cc29542,
    name: "referral-stats"
  }, {
    path: "/referral-add",
    component: _47f4e261,
    name: "referral-add"
  }, {
    path: "/referrals",
    component: _3ebb9136,
    name: "referrals"
  }, {
    path: "/cluster-offer/:id",
    component: _05a6d548,
    name: "cluster-offer"
  }, {
    path: "/list-offers",
    component: _3b398279,
    name: "list-offers"
  }, {
    path: "/office/reports",
    component: _5310c25e,
    name: "office-reports"
  }, {
    path: "/office/me/organizations/:organization_id",
    component: _0d6a6891,
    name: "organization-me"
  }, {
    path: "/office/me",
    component: _1e377d5a,
    name: "office-me"
  }, {
    path: "/partners/:code/organizations/:organization_id",
    component: _0d6a6891,
    name: "organization"
  }, {
    path: "/partners/:code/edit",
    component: _d85fcd34,
    name: "partner-edit"
  }, {
    path: "/partners/:code",
    component: _1e377d5a,
    name: "partner"
  }, {
    path: "/partners/new",
    component: _d85fcd34,
    name: "new-partner"
  }, {
    path: "/partners",
    component: _a73861ee,
    name: "partners"
  }, {
    path: "/audiences/:audience_id/edit",
    component: _60c3fd30,
    name: "audience-edit"
  }, {
    path: "/audience-new",
    component: _6740749c,
    name: "audience-new"
  }, {
    path: "/audiences",
    component: _23b781a5,
    name: "audiences"
  }, {
    path: "/complaints/stores/:store_id/products/:product_id",
    component: _9de59f84,
    name: "complaints"
  }, {
    path: "/complaints/stores/:store_id/products",
    component: _3f59f1fa,
    name: "complaints-products"
  }, {
    path: "/complaints/stores/",
    component: _6be054c8,
    name: "complaints-stores"
  }, {
    path: "/document-collections/:id",
    component: _c512c906,
    name: "single-document-collection"
  }, {
    path: "/document-collections",
    component: _26a46c99,
    name: "document-collections"
  }, {
    path: "/employees/:id/edit",
    component: _6a18fbb3,
    name: "edit-employee"
  }, {
    path: "/employees/:id",
    component: _160e917c,
    name: "employee"
  }, {
    path: "/employees",
    component: _a12181b2,
    name: "employees"
  }, {
    path: "/polygons/",
    component: _c92e70c2,
    name: "polygons"
  }, {
    path: "/supplier-stories/new",
    component: _714c9de1,
    name: "supplier-story-new"
  }, {
    path: "/supplier-stories/:id",
    component: _35352f8e,
    name: "supplier-story-details"
  }, {
    path: "/supplier-stories/:id/edit",
    component: _2a41a3e2,
    name: "supplier-story-edit"
  }, {
    path: "/supplier-stories",
    component: _2fec3752,
    name: "supplier-stories"
  }, {
    path: "/stories/new",
    component: _a4edb9b0,
    name: "story-new"
  }, {
    path: "/stories/:id",
    component: _0c801c00,
    name: "story-details"
  }, {
    path: "/stories/:id/edit",
    component: _363351a8,
    name: "story-edit"
  }, {
    path: "/stories",
    component: _4d141a30,
    name: "stories"
  }, {
    path: "/push-documents/new",
    component: _79daa1b4,
    name: "push-document-new"
  }, {
    path: "/push-documents/:id",
    component: _825bf2b0,
    name: "push-document-details"
  }, {
    path: "/push-documents",
    component: _5b098c65,
    name: "push-documents"
  }, {
    path: "/module-chips",
    component: _226ad176,
    name: "module-chips"
  }, {
    path: "/module-chips-edit/:id",
    component: _1e76e37c,
    name: "module-chips-edit"
  }, {
    path: "/module-chips-new",
    component: _b7b194d0,
    name: "module-chips-new"
  }, {
    path: "/modules",
    component: _05bf0998,
    name: "modules"
  }, {
    path: "/campaigns/new",
    component: _1174590f,
    name: "campaign-new"
  }, {
    path: "/campaigns/:id",
    component: _4228e857,
    name: "campaign-details"
  }, {
    path: "/campaigns",
    component: _71c24979,
    name: "campaigns"
  }, {
    path: "/campaigns/:id/edit",
    component: _6c8ae931,
    name: "campaign-edit"
  }, {
    path: "/analytics/:id",
    component: _334170a3,
    name: "chart-details"
  }, {
    path: "/analytics",
    component: _8842b154,
    name: "analytics"
  }, {
    path: "/requests",
    component: _413ecf3e,
    name: "requests"
  }, {
    path: "/accounting/:id/edit",
    component: _030c1cf8,
    name: "salary-document-edit"
  }, {
    path: "/accounting",
    component: _7154c7de,
    name: "accounting"
  }, {
    path: "/accounting/:id",
    component: _373143c4,
    name: "salary-document-details"
  }, {
    path: "/accounting/c/new",
    component: _3fd24c8c,
    name: "salary-document-new"
  }, {
    path: "/product-group/:id/add",
    component: _41ad106e,
    name: "add-products-to-group"
  }, {
    path: "/product-group/:id",
    component: _72c5bc45,
    name: "product-group"
  }, {
    path: "/product-groups",
    component: _2cc52504,
    name: "product-groups"
  }, {
    path: "/products",
    component: _4bb35b3e,
    name: "products"
  }, {
    path: "/products/new",
    component: _43dd0b09,
    name: "products-new"
  }, {
    path: "/products/:id",
    component: _6561971b,
    name: "products-edit"
  }, {
    path: "/gc/customers/:id",
    component: _c84cc2ce,
    name: "gc-single-customer"
  }, {
    path: "/gc/customers",
    component: _997d4eaa,
    name: "gc-customers"
  }, {
    path: "/orders/:id/refund/",
    component: _5010dad5,
    name: "order-refund"
  }, {
    path: "/orders/:id/edit/",
    component: _b5f67a0a,
    name: "order-edit"
  }, {
    path: "/orders/:id/",
    component: _73a73d65,
    name: "order"
  }, {
    path: "/orders/",
    component: _04ec105e,
    name: "orders"
  }, {
    path: "/password-login",
    component: _24e1dacd,
    name: "password-login"
  }, {
    path: "/sms",
    component: _f0ff9f9a,
    name: "sms"
  }, {
    path: "/login",
    component: _536ab1c3,
    name: "login"
  }, {
    path: "/AppMobileMenuPage",
    component: _0d3f4692,
    name: "AppMobileMenuPage"
  }, {
    path: "/inspire",
    component: _61eeb70a,
    name: "inspire"
  }, {
    path: "/",
    component: _15ed0cb3,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
