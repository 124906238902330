export const state = () => {
  return {
    APP_MENU: null,
    APP_USER_ALLOWED_PARTNERS: null,
    APP_SELECTED_PARTNER: null,
  }
}

export const mutations = {
  SET_APP_MENU(state, serverMenu) {
    // if (this.$config.features.employeesDocuments) {
    //   employeesChildrens.push({
    //     id: 'document-collections',
    //     to: { name: 'document-collections' },
    //     text: 'Бухгалтерия',
    //   })
    // }
    const allMenu = [
      {
        id: 'partners',
        capability: 'partners',
        text: 'Партнеры',
        icon: 'partners',
        to: { name: 'partners' },
        // badge: this.$store.getters['orders/NEW_ORDERS_COUNT'],
      },
      {
        id: 'office',
        capability: 'office',
        text: 'Профиль партнера',
        icon: 'office',
        children: [
          {
            id: 'office-me',
            text: 'Профиль',
            to: { name: 'office-me' },
          },
          // {
          //   id: 'office-reports',
          //   to: { name: 'office-reports' },
          //   text: 'Отчеты',
          // },
        ],
        // badge: this.$store.getters['orders/NEW_ORDERS_COUNT'],
      },
      {
        id: 'good-choice',
        capability: 'good-choice',
        text: 'Хороший выбор',
        icon: 'gc_icon',
        to: { name: 'customers' },
        children: [
          // {
          //   id: 'customers',
          //   capability: 'good-choice.customers',
          //   text: 'old Покупатели',
          //   to: { name: 'customers' },
          // },
          {
            id: 'gc-customers',
            capability: 'good-choice.customers',
            text: 'Покупатели',
            to: { name: 'gc-customers' },
          },
          {
            id: 'orders',
            capability: 'good-choice.orders',
            text: 'Заказы',
            to: { name: 'orders' },
          },
        ],
      },

      {
        id: 'employees',
        capability: 'rost-worker',
        text: 'Карьерный рост',
        icon: 'rost-worker_icon',
        children: [
          {
            id: 'employees',
            capability: 'rost-worker.employees',
            text: 'Сотрудники',
            to: { name: 'employees' },
          },
          {
            id: 'requests',
            capability: 'rost-worker.requests',
            text: 'Запросы',
            to: { name: 'requests' },
          },
          {
            id: 'accounting',
            capability: 'rost-worker.accounting',
            text: 'Выплаты',
            to: { name: 'accounting' },
          },
        ],
      },
      {
        id: 'document-collections',
        capability: 'reports',
        text: 'Отчеты',
        icon: 'documents',
        to: { name: 'document-collections' },
      },
      {
        id: 'products-group',
        capability: 'products',
        text: 'Товары',
        icon: 'goods',
        to: { name: 'products' },
        children: [
          {
            id: 'products',
            text: 'Управление товарами',
            capability: 'products.products-list',
            to: { name: 'products' },
          },
          {
            id: 'complaints-stores',
            text: 'Жалобы на товары',
            capability: 'products.complaints',
            to: { name: 'complaints-stores' },
          },
        ],
      },

      {
        id: 'analytics',
        capability: 'analytics',
        text: 'Аналитика',
        icon: 'analytics',
        to: { name: 'analytics' },
      },
      {
        id: 'marketing',
        capability: 'marketing',
        text: 'Маркетинг',
        icon: 'marketing',
        children: [
          {
            id: 'company',
            capability: 'marketing.campaigns',
            text: 'Кампании',
            to: { name: 'campaigns' },
          },
          {
            id: 'company-chips',
            capability: 'marketing.chips',
            text: 'Фишки',
            to: { name: 'module-chips' },
          },
          {
            id: 'push',
            capability: 'marketing.notifications',
            text: 'Уведомления',
            to: { name: 'push-documents' },
          },
          {
            id: 'stories',
            capability: 'marketing.stories',
            text: 'Сторис',
            to: { name: 'stories' },
          },
          {
            id: 'supplier-stories',
            capability: 'marketing.supplier-stories',
            text: 'Сторис поставщика',
            to: { name: 'supplier-stories' },
          },
          {
            id: 'product-groups',
            text: 'Товарные группы',
            capability: 'marketing.product-groups',
            to: { name: 'product-groups' },
          },
          {
            id: 'audiences',
            capability: 'marketing.audiences',
            text: 'Аудитории',
            to: { name: 'audiences' },
          },
          {
            id: 'list-offers',
            capability: 'marketing.lama-cluster-offer',
            text: 'Кластерное предложение',
            to: { name: 'list-offers' },
          },
          {
            id: 'referrals',
            capability: 'marketing.referrals',
            text: 'Реферальная ссылка',
            to: { name: 'referrals' },
          },
          // {
          //   id: 'mailing',
          //   to: { name: 'mailing' },
          //   text: 'Рассылки',
          //   inDev: true,
          // },
        ],
      },
      {
        id: 'polygons',
        capability: 'polygons',
        text: 'Зоны доставки',
        icon: 'path',
        to: { name: 'polygons' },
      },
    ]

    if (this.$config.features.adminModule) {
      allMenu.push({
        id: 'admin',
        capability: 'admin',
        icon: 'Road',
        text: 'Админ',
        children: [
          {
            id: 'admin-partners-profile',
            toFunction: () => ({
              name: 'admin-partners-profile',
              params: { id: this.$appGlobal.selectedPartnerId },
            }),
            text: 'Профиль партнера',
            // inDev: true,
          },
          {
            id: 'admin-access-control-users',
            to: {
              name: 'admin-access-control-users',
            },
            text: 'Управление доступом',
            // inDev: true,
          },
          {
            id: 'admin-finance',
            to: {
              name: 'admin-finance',
            },
            text: 'Финансы',
            // inDev: true,
          },
        ],
      })
    }

    const availableMenuItems = serverMenu
      .map((el) => el.code)
      .concat([
        'admin',
        // 'accounting',
        // 'requests',
        // 'products-group',
        // 'analytics',
        // 'marketing',
      ])

    state.APP_MENU = [
      {
        id: 'main',
        text: 'Главная',
        icon: 'home',
        to: '/',
      },
      ...allMenu.filter((el) => {
        const matchingCapability = availableMenuItems.includes(el.capability)
        if (matchingCapability) {
          if (el.children) {
            el.children = el.children.filter((children) =>
              children.capability
                ? availableMenuItems.includes(children.capability)
                : true
            )
          }
          return true
        }
        return false
      }),
    ]
  },
  SET_APP_USER_PARTNERS(state, list) {
    state.APP_USER_ALLOWED_PARTNERS = list
  },
  SET_APP_SELECTED_PARTNER(state, partner) {
    state.APP_SELECTED_PARTNER = partner
  },
}

export const actions = {}

export const getters = {
  GET_APP_SELECTED_PARTNER_ID(state) {
    return state.APP_SELECTED_PARTNER?.id
  },
}
