import Vue from 'vue'

export default {
  beforeCreate() {
    Vue.util.defineReactive(this, '$appFetchState', {
      pending: false,
      error: null,
      rawError: null,
    })

    this.$appFetch = appFetch.bind(this)
  },
}

async function appFetch(reqestFn, id = null) {
  this.$appFetchState.error = null
  this.$appFetchState.rawError = null
  this.$appFetchState.pending = true
  if (id) {
    this.$appFetchState.pending = { [id]: true }
    this.$appFetchState.error = { [id]: null }
  }

  let error = null
  let rawError = null
  let resp = null

  try {
    resp = await reqestFn()
  } catch (err) {
    if (process.dev) {
      console.error('Error in fetch():', err)
    }

    if (err.isAxiosError) {
      error =
        err.response?.data?.status?.message || err.response?.data || err.message

      rawError = err.response?.data || err
    } else {
      throw err
      // error = err
    }
  }

  this.$appFetchState.error = error
  this.$appFetchState.rawError = rawError
  this.$appFetchState.pending = false
  if (id) {
    this.$appFetchState.pending = { [id]: false }
    this.$appFetchState.error = { [id]: error }
  }

  return resp
}
